<template>
  <div class="admin-announcements">
    <div class="header">
      <h1>Announcements</h1>

      <router-link tag="a" class="btn" to="/admin/announcements/new">
        <img src="../../assets/img/admin-career/add-new.svg">Add a new announcement
      </router-link>

      <div class="section-area-announcements grid">
        
      </div>

    </div>
  </div>
</template>

<script>
import PostPreviewNewsAndAnnouncements from '@/components/partials/PostPreviewNewsAndAnnouncements.vue';

export default {
  name: 'admin-announcement',
  data: function() {
    return {
      username: null
    };
  },
  methods: {
    async getUser() {
      try {
        let user = await axios({
          method: "get",
          url: "http://testdeployd.aezypay.com/users/me"
        });
        return user;
      } catch (error) {
        console.error("error");
      }
    }
  }
};
</script>

<style lang="scss">
.admin-announcements {
  background: #f9f9f9;
  padding: 50px;
  min-height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    h1 {
      color: #6479ae;
      font-size: 22px;
      font-weight: 600;
    }
    .btn {
      background-color: #6479ae;
      border-radius: 30px;
      padding: 7px;
      color: white;
      width: 300px;
      img {
        width: 36px;
        padding-right: 10px;
      }
    }
  }

  .section-area-announcements.grid { 
    display: grid;
    grid-gap: 60px;
    grid-template-columns: auto auto;
    grid-auto-flow: row;
  }
}
</style>

<template>
    <div class="col-12 col-md-6">
        <article class="post-preview-news-and-announcements">

            <!-- Post Thumbnail -->
            <div class="post-thumbnail" :style="'background-image: url(' + imageSrc + ')'">
                <span class="post-category">{{ category }}</span>
            </div>

            <!-- Post Details -->
            <div class="post-details">
                
                <!-- Post Meta Description -->
                <div class="post-meta-description">
                    <span><img class="icon date-and-time" src="@/assets/img/archive-news-and-announcements/date-and-time.svg"/> {{ getPostDate }}</span>
                    <span><img class="icon author" src="@/assets/img/archive-news-and-announcements/author.svg"/> {{ author }}</span>
                </div>

                <!-- Post Content -->
                <div class="post-content">
                    <router-link class="post-link" :to="{ path: 'single-news-and-announcements/'+ this.id }">
                        <h2 class="title">{{ title }}</h2>
                        <p class="description">{{ description }}</p>
                    </router-link>
                </div>

                <router-link class="read-more"  :to="{ path: 'single-news-and-announcements/' + this.id }">{{ $t('archiveNews.readMore') }}... <img class="icon-read-more" src="../../assets/img/read-more.svg" /></router-link>

            </div>

        </article>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import MultiClamp from 'multi-clamp';

export default {
    name: "PostPreviewNewsAndAnnouncements",
    props: {
        id: String,
        postDate: String,
        author: String,
        title: String,
        description: String, 
        image: String,
        category: String,
    },
    computed: {
        imageSrc: function() {
            return require ('../../assets/img/archive-news-and-announcements/' + this.image);
        },
        getPostDate: function() {
            let postDate = new Date(this.postDate);

            let date = postDate.getDate();
            let month = (postDate.getMonth() + 1);
            let year = postDate.getFullYear();

            if (date < 10) {
                date = '0' + date;
            }

            if (month < 10) {
                month = '0' + month;
            }

            let hour = postDate.getTime();
            let minute = postDate.getMinutes();
            let period = (hour < 12) ? 'AM' : 'PM';

            return `${date}-${month}-${year} ${hour}:${minute} ${period}`;
        }
    },
    created: function() {
        $(document).ready(() => {
            $('.post-content .title').each(function(index, element) {
                new MultiClamp(element, {
                    ellipsis: '...',
                    clamp: 2
                });
            });

            $('.post-content .description').each(function(index, element) {
                new MultiClamp(element, {
                    ellipsis: '...',
                    clamp: 2
                });
            });
        });
    },
    components: {
        
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/post-preview-news-and-announcements.scss';
</style>